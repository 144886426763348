import React, { useContext, useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Header.css';
import { AuthContext } from '../context/AuthContext';
import { jwtDecode } from 'jwt-decode'; // Token çözümlemesi için ekleyin

const Header = () => {
  const { authState, logout } = useContext(AuthContext);
  const [isMenuOpen, setIsMenuOpen] = useState(false); // Mobil menü durumu
  const [userName, setUserName] = useState('Kullanıcı'); // Varsayılan isim
  const navigate = useNavigate();

  // Logo tıklama işlemi
  const handleLogoClick = () => {
    navigate('/');
  };

  // Profil sayfasına geçiş
  const handleProfileClick = () => {
    navigate('/profile');
  };

  // Mobil menü aç/kapat
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  // Sayfa yüklendiğinde token'dan kullanıcı adı kontrol et
  useEffect(() => {
    const token = localStorage.getItem('token'); // Token'ı localStorage'dan al
    if (token) {
      try {
        const decoded = jwtDecode(token); // Token'ı çöz
        setUserName(decoded.name || 'Kullanıcı'); // Token'dan adı al
      } catch (error) {
        console.error('Token çözümleme hatası:', error);
        logout(); // Token geçersizse çıkış yap
      }
    } else if (authState.user?.name) {
      // AuthState üzerinden kullanıcı adını al
      setUserName(authState.user.name);
    }
  }, [authState.user]); // AuthState değiştiğinde tetiklenir

  return (
    <header className="header">
      {/* Logo Bölümü */}
      <div>
        <button
          className="logo-button"
          onClick={handleLogoClick}
          style={{ cursor: 'pointer' }}
          aria-label="Go to Home Page"
        >
          hocabul.net
        </button>
      </div>

      

      {/* Mobil Menü */}
      <div className="hamburger" onClick={toggleMenu} aria-label="Toggle Menu">
        ☰
      </div>

      {/* Auth Bölümü */}
      <nav className={`auth-container ${isMenuOpen ? 'open' : ''}`}>
        {authState.isTeacherAuthenticated ? ( // Eğer giriş yapılmışsa
          <div className="user-section">
            <span className="welcome-message">
              Hoşgeldiniz Hocam!
            </span>
            <button
              className="profile-button"
              onClick={handleProfileClick}
              aria-label="Go to Profile"
            >
              Profilim
            </button>
            <span className="divider">|</span>
            <span className="logout" onClick={logout}>
              Çıkış Yap
            </span>
          </div>
        ) : (
          // Eğer giriş yapılmamışsa
          <>
            <Link to="/login" className="auth-link">
              Öğretmen Giriş Yap
            </Link>
            <span className="divider">|</span>
            <Link to="/signup" className="auth-link">
              Öğretmen Hesap Aç
            </Link>
          </>
        )}
      </nav>
    </header>
  );
};

export default Header;
