import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Profile from '../components/Profile';
import ContactInfo from '../components/ContactInfo';
import ReactPlayer from 'react-player';
import { FaLinkedin, FaInstagram, FaYoutube, FaGlobe } from 'react-icons/fa';
import { SiTiktok } from 'react-icons/si'; // TikTok ikonunu ekleyin
import { useNavigate, useParams } from 'react-router-dom';

const CVContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #34445b;
  padding: 30px;
  max-width: 800px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  position: relative;
`;

const Card = styled.div`
  background-color: #ffffff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin: 5px 0;
  width: 100%;
  max-width: 700px;
  p {
    text-align: left;
  }
`;

const Heading = styled.h3`
  font-size: 1.2em;
  color: #333;
  font-weight: bold;
  margin-bottom: 10px;
`;

const SocialIcons = styled.div`
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-top: 10px;
`;

const IconLink = styled.a`
  color: #555;
  font-size: 1.5em;

  &:hover {
    color: #000;
  }
`;

const ExpertiseContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
  gap: 15px;
  padding: 10px;
`;

const ExpertiseItem = styled.div`
  background-color: #F06060;
  color: #ffffff;
  text-align: center;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  font-size: 0.9em;
  font-weight: bold;
`;

function ExpertiseSection({ categories }) {
  return (
    <ExpertiseContainer>
      {categories.map((category, index) => (
        <ExpertiseItem key={index}>{category}</ExpertiseItem>
      ))}
    </ExpertiseContainer>
  );
}

function CV() {
  const { email } = useParams();
  const [teacherData, setTeacherData] = useState(null);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTeacherData = async () => {
      try {
        const response = await fetch(`https://hocabul.net/api/teacher-cv/${encodeURIComponent(email)}`);
        if (response.ok) {
          const data = await response.json();
          setTeacherData(data);
        } else {
          setError('Öğretmen bilgileri bulunamadı.');
        }
      } catch (error) {
        setError('Veri alınırken bir hata oluştu.');
        console.error('Error fetching teacher data:', error);
      }
    };

    fetchTeacherData();
  }, [email]);

  if (error) {
    return <p>{error}</p>;
  }

  if (!teacherData) {
    return <p>Bilgiler yükleniyor...</p>;
  }

  const handleDelete = async () => {
    if (!window.confirm('Bu CV\'yi silmek istediğinize emin misiniz?')) return;

    try {
      const response = await fetch(`/api/delete-cv`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, category: teacherData?.category }),
      });

      if (response.ok) {
        alert('CV başarıyla silindi.');
        navigate('/team');
      } else {
        const error = await response.json();
        console.error('Delete failed:', error.message);
      }
    } catch (error) {
      console.error('Error deleting CV:', error);
    }
  };

  const imageUrl = teacherData.photoPath
    ? `/uploads/${teacherData.photoPath}`
    : '/images/default-teacher.png';

  return (
    <CVContainer>
      <Card>
        <Profile name={`${teacherData.name} ${teacherData.lastname}`} photo={imageUrl} />
        <SocialIcons>
          {teacherData.linkedin && (
            <IconLink href={teacherData.linkedin} target="_blank" rel="noopener noreferrer">
              <FaLinkedin />
            </IconLink>
          )}
          {teacherData.instagram && (
            <IconLink href={teacherData.instagram} target="_blank" rel="noopener noreferrer">
              <FaInstagram />
            </IconLink>
          )}
          {teacherData.tiktok && (
            <IconLink href={teacherData.tiktok} target="_blank" rel="noopener noreferrer">
              <SiTiktok />
            </IconLink>
          )}
          {teacherData.youtubeChannel && (
            <IconLink href={teacherData.youtubeChannel} target="_blank" rel="noopener noreferrer">
              <FaYoutube />
            </IconLink>
          )}
          {teacherData.web && (
            <IconLink href={teacherData.web} target="_blank" rel="noopener noreferrer">
              <FaGlobe />
            </IconLink>
          )}
        </SocialIcons>
      </Card>
      <Card>
        <ContactInfo
          phone={teacherData.phone}
          email={teacherData.email}
          detailedAddress={teacherData.detailedAddress}
        />
      </Card>
      {teacherData.about && (
        <Card>
          <Heading>HAKKIMDA</Heading>
          <p>{teacherData.about}</p>
        </Card>
      )}
      {(teacherData.highSchool || teacherData.master || teacherData.PhD) && (
        <Card>
          <Heading>EĞİTİM</Heading>
          {teacherData.highSchool && <p>Lise: {teacherData.highSchool}</p>}
          {teacherData.master && <p>Yüksek Lisans: {teacherData.master}</p>}
          {teacherData.PhD && <p>Doktora: {teacherData.PhD}</p>}
        </Card>
      )}
      {teacherData.categories && teacherData.categories.length > 0 && (
        <Card>
          <Heading>UZMANLIK ALANLARI</Heading>
          <ExpertiseSection categories={teacherData.categories} />
        </Card>
      )}
      {teacherData.youtubePromo && (
        <Card>
          <Heading>VİDEO TANITIM</Heading>
          <ReactPlayer url={teacherData.youtubePromo} controls width="100%" />
        </Card>
      )}
    </CVContainer>
  );
}

export default CV;
