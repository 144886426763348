import React, { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./PhotoTool.css";

const PhotoTool = () => {
  const [imageSrc, setImageSrc] = useState(null); // Yüklenen görsel
  const [isDragging, setIsDragging] = useState(false); // Sürükleme durumu
  const [isResizing, setIsResizing] = useState(false); // Yeniden boyutlandırma durumu
  const [position, setPosition] = useState({ x: 0, y: 0 }); // Görsel pozisyonu
  const [startPosition, setStartPosition] = useState({ x: 0, y: 0 }); // Farenin başlangıç pozisyonu
  const [resizeCorner, setResizeCorner] = useState(null); // Hangi köşe sürükleniyor
  const [imageDimensions, setImageDimensions] = useState({ width: 0, height: 0 }); // Görsel boyutları
  const canvasRef = useRef(null);
  const navigate = useNavigate();

  // Fotoğraf yükleme işlevi
  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setImageSrc(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // Fotoğrafı Canvas üzerine çizme
  useEffect(() => {
    if (imageSrc && canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      const image = new Image();
      image.onload = () => {
        const scale = Math.min(
          canvas.width / image.width,
          canvas.height / image.height
        );
        const newWidth = image.width * scale;
        const newHeight = image.height * scale;

        setImageDimensions({ width: newWidth, height: newHeight });

        const x = (canvas.width - newWidth) / 2;
        const y = (canvas.height - newHeight) / 2;
        setPosition({ x, y });

        drawCanvas(ctx, image, x, y, newWidth, newHeight);
      };
      image.src = imageSrc;
    }
  }, [imageSrc]);

  // Canvas çizimi
  const drawCanvas = (ctx, image, x, y, width, height) => {
    ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);
    ctx.drawImage(image, x, y, width, height);

    // Resize tutucularını çiz
    const handleSize = 10;
    ctx.fillStyle = "blue";

    ctx.fillRect(x - handleSize / 2, y - handleSize / 2, handleSize, handleSize); // Sol üst
    ctx.fillRect(
      x + width - handleSize / 2,
      y - handleSize / 2,
      handleSize,
      handleSize
    ); // Sağ üst
    ctx.fillRect(
      x - handleSize / 2,
      y + height - handleSize / 2,
      handleSize,
      handleSize
    ); // Sol alt
    ctx.fillRect(
      x + width - handleSize / 2,
      y + height - handleSize / 2,
      handleSize,
      handleSize
    ); // Sağ alt
  };

  // Fare olaylarını işleme
  const handleMouseDown = (e) => {
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;

    // Yeniden boyutlandırma alanına tıklama kontrolü
    const resizeHandleSize = 10;
    if (
      mouseX >= position.x - resizeHandleSize / 2 &&
      mouseX <= position.x + resizeHandleSize / 2 &&
      mouseY >= position.y - resizeHandleSize / 2 &&
      mouseY <= position.y + resizeHandleSize / 2
    ) {
      setIsResizing(true);
      setResizeCorner("top-left");
    } else if (
      mouseX >= position.x + imageDimensions.width - resizeHandleSize / 2 &&
      mouseX <= position.x + imageDimensions.width + resizeHandleSize / 2 &&
      mouseY >= position.y - resizeHandleSize / 2 &&
      mouseY <= position.y + resizeHandleSize / 2
    ) {
      setIsResizing(true);
      setResizeCorner("top-right");
    } else if (
      mouseX >= position.x - resizeHandleSize / 2 &&
      mouseX <= position.x + resizeHandleSize / 2 &&
      mouseY >= position.y + imageDimensions.height - resizeHandleSize / 2 &&
      mouseY <= position.y + imageDimensions.height + resizeHandleSize / 2
    ) {
      setIsResizing(true);
      setResizeCorner("bottom-left");
    } else if (
      mouseX >= position.x + imageDimensions.width - resizeHandleSize / 2 &&
      mouseX <= position.x + imageDimensions.width + resizeHandleSize / 2 &&
      mouseY >= position.y + imageDimensions.height - resizeHandleSize / 2 &&
      mouseY <= position.y + imageDimensions.height + resizeHandleSize / 2
    ) {
      setIsResizing(true);
      setResizeCorner("bottom-right");
    } else {
      // Sürükleme işlemi
      setIsDragging(true);
      setStartPosition({ x: mouseX - position.x, y: mouseY - position.y });
    }
  };

  const handleMouseMove = (e) => {
    if (!canvasRef.current) return;

    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const mouseX = e.clientX - rect.left;
    const mouseY = e.clientY - rect.top;

    const ctx = canvas.getContext("2d");
    const image = new Image();
    image.src = imageSrc;

    image.onload = () => {
      if (isDragging) {
        setPosition({
          x: mouseX - startPosition.x,
          y: mouseY - startPosition.y,
        });
        drawCanvas(
          ctx,
          image,
          mouseX - startPosition.x,
          mouseY - startPosition.y,
          imageDimensions.width,
          imageDimensions.height
        );
      } else if (isResizing) {
        let newWidth = imageDimensions.width;
        let newHeight = imageDimensions.height;

        if (resizeCorner === "top-left") {
          newWidth += position.x - mouseX;
          newHeight = newWidth * (imageDimensions.height / imageDimensions.width);
          setPosition({ x: mouseX, y: mouseY });
        } else if (resizeCorner === "top-right") {
          newWidth = mouseX - position.x;
          newHeight = newWidth * (imageDimensions.height / imageDimensions.width);
        } else if (resizeCorner === "bottom-left") {
          newWidth += position.x - mouseX;
          newHeight = newWidth * (imageDimensions.height / imageDimensions.width);
          setPosition({ x: mouseX, y: position.y });
        } else if (resizeCorner === "bottom-right") {
          newWidth = mouseX - position.x;
          newHeight = newWidth * (imageDimensions.height / imageDimensions.width);
        }

        setImageDimensions({ width: newWidth, height: newHeight });
        drawCanvas(ctx, image, position.x, position.y, newWidth, newHeight);
      }
    };
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setIsResizing(false);
    setResizeCorner(null);
  };

  const handleSave = () => {
    const canvas = canvasRef.current;
    const link = document.createElement("a");
    canvas.toBlob((blob) => {
      link.download = "edited_image.png";
      link.href = URL.createObjectURL(blob);
      link.click();
    }, "image/png");
  };

  const handleContinue = () => {
    navigate("/profile");
  };

  return (
    <div className="photo-tool">
      <h1>Fotoğraf Düzenleme Aracı</h1>
      <p>
        Lütfen yüzünüzü canvasın merkezine yerleştirip, resminizi büyütüp küçülterek canvası tamamen doldurunuz.
      </p>
      <input type="file" accept="image/*" onChange={handleImageUpload} />

      <div
        className="editor"
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      >
        <canvas
          ref={canvasRef}
          width={500}
          height={500}
          style={{ border: "2px dashed #000" }}
        ></canvas>
      </div>

      {imageSrc && (
        <div>
          <button onClick={handleSave} className="save-btn">
            Fotoğrafı Kaydet
          </button>
          <button onClick={handleContinue} className="continue-btn">
            Başvurunuza Devam Edin
          </button>
        </div>
      )}
    </div>
  );
};

export default PhotoTool;
