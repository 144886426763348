import React, { createContext, useState, useEffect } from 'react';
import { jwtDecode } from 'jwt-decode';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authState, setAuthState] = useState({
    isAdminAuthenticated: false,
    isTeacherAuthenticated: false,
    token: null,
    user: null,
  });

  // Token kontrolü ve doğrulama
  useEffect(() => {
    const token = localStorage.getItem("token");
    const isAdmin = localStorage.getItem('isAdminAuthenticated') === 'true';

    if (isAdmin) {
      // Admin için sadece localStorage kontrolü yeterli
      setAuthState({
        isAdminAuthenticated: true,
        isTeacherAuthenticated: false,
        token: null,
        user: null,
      });
    } else if (token) {
      try {
        // Token varsa doğrula ve kullanıcıyı ayarla
        const decoded = jwtDecode(token);

        if (decoded.exp * 1000 < Date.now()) {
          // Token süresi dolmuşsa logout yap
          handleInvalidToken();
        } else {
          setAuthState({
            isAdminAuthenticated: false,
            isTeacherAuthenticated: decoded.role === "teacher",
            token,
            user: { email: decoded.email, name: decoded.name },
          });
        }
      } catch (error) {
        console.error("Token geçersiz:", error);
        handleInvalidToken(); // Hatalı token durumunu ele al
      }
    }
  }, []);

  // Token süresi dolduğunda yapılacak işlem
  const handleInvalidToken = () => {
    localStorage.removeItem("token");
    setAuthState({
      isAdminAuthenticated: false,
      isTeacherAuthenticated: false,
      token: null,
      user: null,
    });
    alert("Oturum süreniz doldu, lütfen tekrar giriş yapın.");
  };

  // Admin Login
  const loginAsAdmin = (userData) => {
    try {
      setAuthState({ 
        isAdminAuthenticated: true, 
        isTeacherAuthenticated: false, 
        user: userData, 
        token: null 
      });
      localStorage.setItem('isAdminAuthenticated', 'true');
      localStorage.removeItem('token'); // Admin'de token tutulmuyor
    } catch (error) {
      console.error('Error in loginAsAdmin:', error);
    }
  };

  // Teacher Login
  const loginAsTeacher = (userData, token) => {
    try {
      localStorage.setItem("token", token);
      setAuthState({
        isAdminAuthenticated: false,
        isTeacherAuthenticated: true,
        token,
        user: userData,
      });
    } catch (error) {
      console.error('Error in loginAsTeacher:', error);
    }
  };

  // Logout
  const logout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem('isAdminAuthenticated');
    setAuthState({
      isAdminAuthenticated: false,
      isTeacherAuthenticated: false,
      token: null,
      user: null,
    });
  };

  return (
    <AuthContext.Provider value={{ authState, loginAsAdmin, loginAsTeacher, logout }}>
      {children}
    </AuthContext.Provider>
  );
};
